import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LinkedInOAuth from './components/LinkedInOAuth';
import PrivacyPolicy from './components/PrivacyPolicy';
import RenderData from './components/RenderData';
import { decryptJSON } from './decrypt';
import './App.css';


const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
function App() {
  const [content, setContent] = useState(null);
  const [isDarkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
      return JSON.parse(savedMode);
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  const [isFunMode, setFunMode] = useState(false);
  const [user, setUser] = useState(() => {
    return localStorage.getItem('user') || null;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLinkedInCallback = async (code) => {
    try {
      setIsLoading(true);
      const response = await fetch(`/api/auth?code=${code}`);
      const encryptedData = await response.json();
      const data = decryptJSON(encryptedData.content, encryptionKey);
      if (data && encryptedData.user.name) {
        setUser(encryptedData.user.name);
        localStorage.setItem('user', encryptedData.user.name);
        setContent(data);
      } else {
        setError('Invalid response from server');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error during LinkedIn login:', error);
      setError('Authentication failed. Please try again.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleLinkedInCallback(code);
      // Clear the URL params
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const toggleDarkMode = (checked) => {
    if (checked === undefined) {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setDarkMode(systemPrefersDark);
    } else {
      setDarkMode(checked);
    }
  };

  const toggleFunMode = () => {
    setFunMode(prevMode => !prevMode);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      toggleDarkMode(e.matches);
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return (
    <Router>
      <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
        <Routes>
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/" element={
            !user || !content ? (
              <div className="signin-page">
                <h1>Welcome to My Portfolio</h1>
                <p>Please sign in with LinkedIn to view the content.</p>
                <p>and if you really, really don't want to you can <a href="#" onClick={(e) => {
                  e.preventDefault();
                  const code = prompt("Please enter your code:");
                  if (code) {
                    window.location.href = `?code=${encodeURIComponent(code)}`;
                  }
                }}>use a code</a></p>
                <LinkedInOAuth isLoading={isLoading} onClick={() => setIsLoading(true)} />
                {error && <div>{error}</div>}
              </div>
            ) : (
              <RenderData
                content={content}
                isDarkMode={isDarkMode}
                toggleDarkMode={toggleDarkMode}
                isFunMode={isFunMode}
                toggleFunMode={toggleFunMode}
              />
            )
          } />
        </Routes>
      </div>
    </Router>
  );
}
export default App;