import * as Si from 'react-icons/si';
import {FaNetworkWired, FaJava} from 'react-icons/fa';
import {MdWork} from 'react-icons/md';
import {IoAnalyticsOutline} from 'react-icons/io5';
import { GiTeamUpgrade } from 'react-icons/gi';


export const iconMap = {
    "linkedin": <Si.SiLinkedin />,
    "git": <Si.SiGit />,
    "python": <Si.SiPython />,
    "javascript": <Si.SiJavascript />,
    "typescript": <Si.SiTypescript />,
    "react": <Si.SiReact />,
    "nodejs": <Si.SiNodedotjs />,
    "html": <Si.SiHtml5 />,
    "css": <Si.SiCss3 />,
    "julia": <Si.SiJulia />,
    "excel": <Si.SiMicrosoftexcel />,
    "email": <Si.SiGmail />,
    "work_email": <Si.SiMaildotru />,
    "docker": <Si.SiDocker />,
    "kubernetes": <Si.SiKubernetes />,
    "firebase": <Si.SiFirebase />,
    "mongodb": <Si.SiMongodb />,
    "mysql": <Si.SiMysql />,
    "qlik": <Si.SiQlik />,
    "postgresql": <Si.SiPostgresql />,
    "redis": <Si.SiRedis />,
    "vscode": <Si.SiVisualstudiocode />,
    "mocha": <Si.SiMocha />,
    "jquery": <Si.SiJquery />,
    "bootstrap": <Si.SiBootstrap />,
    "tailwind": <Si.SiTailwindcss />,
    "npm": <Si.SiNpm />,
    "yarn": <Si.SiYarn />,
    "linux": <Si.SiLinux />,
    "bash": <Si.SiGnubash />,
    "nginx": <Si.SiNginx />,
    "heroku": <Si.SiHeroku />,
    "netlify": <Si.SiNetlify />,
    "vercel": <Si.SiVercel />,
    "jira": <Si.SiJira />,
    "slack": <Si.SiSlack />,
    "trello": <Si.SiTrello />,
    "notion": <Si.SiNotion />,
    "figma": <Si.SiFigma />,
    "adobexd": <Si.SiAdobexd />,
    "photoshop": <Si.SiAdobephotoshop />,
    "illustrator": <Si.SiAdobeillustrator />,
    "nextjs": <Si.SiNextdotjs />,
    "angular": <Si.SiAngular />,
    "vuejs": <Si.SiVuedotjs />,
    "azure": <Si.SiMicrosoftazure />,
    "digitalocean": <Si.SiDigitalocean />,
    "go": <Si.SiGo />,
    "rust": <Si.SiRust />,
    "php": <Si.SiPhp />,
    "ruby": <Si.SiRuby />,
    "c#": <Si.SiCsharp />,
    "c++": <Si.SiCplusplus />,
    "java": <FaJava />,
    "gitlab": <Si.SiGitlab />,
    "bitbucket": <Si.SiBitbucket />,
    "jenkins": <Si.SiJenkins />,
    "githubactions": <Si.SiGithubactions />,
    "cloudflare": <Si.SiCloudflare />,
    "sqlite": <Si.SiSqlite />,
    "swagger": <Si.SiSwagger />,
    "openapi": <Si.SiOpenapiinitiative />,
    "hadoop": <Si.SiApachehadoop />,
    "spark": <Si.SiApachespark />,
    "tableau": <Si.SiTableau />,
    "powerbi": <Si.SiMicrosoftpowerpoint />,
    "langchain": <Si.SiLangchain />,
    "streamlit": <Si.SiStreamlit />,
    "bigquery": <Si.SiGooglebigquery />,
    "zoho": <Si.SiZoho />,
    "salesforce": <Si.SiSalesforce />,
    "webflow": <Si.SiWebflow />,
    "security": <Si.SiSpringsecurity />,
    "sql": <Si.SiMysql />,
    "google_analytics": <Si.SiGoogleanalytics />,
    "arduino": <Si.SiArduino />,
    "bluetooth": <Si.SiBluetooth />,
    "unifi": <Si.SiUbiquiti />,
    "homeassistant": <Si.SiHomeassistant />,
    "homebridge": <Si.SiHomebridge />,
    "tensorflow": <Si.SiTensorflow />,
    "pytorch": <Si.SiPytorch />,
    "shopify": <Si.SiShopify />,
    "raspberrypi": <Si.SiRaspberrypi />,
    "huggingface": <Si.SiHuggingface />,
    "networking": <FaNetworkWired />,
    "entrepreneurship": <MdWork />,
    "leadership": <GiTeamUpgrade />,
    "analytics": <IoAnalyticsOutline />,
    "looker":<Si.SiLooker />,
    "github": <Si.SiGithub />
};