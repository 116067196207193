import React, { useEffect, useRef } from 'react';

const FunText = ({ text, isFunMode }) => {
    const textRef = useRef(null);
  
    useEffect(() => {
      if (!isFunMode || !textRef.current) return;
  
      const textElement = textRef.current;
  
      const handleInteraction = (clientX, clientY) => {
        const mouseX = clientX;
        const mouseY = clientY;
  
        textElement.querySelectorAll('.fun-mode-letter').forEach(span => {
          const spanBounds = span.getBoundingClientRect();
          const spanCenterX = spanBounds.left + spanBounds.width / 2;
          const spanCenterY = spanBounds.top + spanBounds.height / 2;
  
          const distX = spanCenterX - mouseX;
          const distY = spanCenterY - mouseY;
  
          const distance = Math.sqrt(distX * distX + distY * distY);
  
          if (distance < 40) {
            const angle = Math.atan2(distY, distX);
            const scale = 60;
            const dx = Math.cos(angle) * scale;
            const dy = Math.sin(angle) * scale;
  
            span.style.setProperty('--dx', `${dx}px`);
            span.style.setProperty('--dy', `${dy}px`);
            span.style.setProperty('--dΘ', `${Math.random() * 360}deg`);
            span.classList.add('exploded');
          } else {
            span.classList.remove('exploded');
          }
        });
      };
  
      const handleMouseMove = (e) => {
        handleInteraction(e.clientX, e.clientY);
      };
  
      const handleTouchMove = (e) => {
        e.preventDefault();
        const touch = e.touches[0];
        handleInteraction(touch.clientX, touch.clientY);
      };
  
      const handleLeave = () => {
        textElement.querySelectorAll('.fun-mode-letter').forEach(span => {
          span.classList.remove('exploded');
        });
      };
  
      textElement.addEventListener('mousemove', handleMouseMove);
      textElement.addEventListener('touchmove', handleTouchMove, { passive: false });
      textElement.addEventListener('mouseleave', handleLeave);
      textElement.addEventListener('touchend', handleLeave);
  
      return () => {
        if (textElement) {
          textElement.removeEventListener('mousemove', handleMouseMove);
          textElement.removeEventListener('touchmove', handleTouchMove);
          textElement.removeEventListener('mouseleave', handleLeave);
          textElement.removeEventListener('touchend', handleLeave);
        }
      };
    }, [isFunMode]);
  
    return (
      <span ref={textRef} className={`fun-text ${isFunMode ? 'fun-mode-active' : ''}`}>
        {(text || '').split(' ').map((word, wordIndex) => (
          <span key={wordIndex} className="fun-mode-word" style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
            {word.split('').map((char, charIndex) => (
              <span key={charIndex} className="fun-mode-letter" style={{ display: 'inline-block' }}>
                {char}
              </span>
            ))}
            {wordIndex !== (text || '').split(' ').length - 1 && '\u00A0'}
          </span>
        ))}
      </span>
    );
  };
  
  export default FunText;