import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: May 2024</p>
      <p> I only save your email to logs just to see who views the site. Everything below is defintely not ChatGPT writing compliance stuff for lawyers, it is very true :)</p>
      
      <h2>1. Introduction</h2>
      <p>This Privacy Policy describes how MB ("we", "us", or "our") collects, uses, and shares your personal information when you use our website https://bensa.us (the "Site").</p>

      <h2>2. Information We Collect</h2>
      <p>We collect information you provide directly to us, such as when you create an account, submit a form, or communicate with us. This may include your name, email address, and any other information you choose to provide.</p>

      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to comply with legal obligations.</p>

      <h2>4. Sharing of Information</h2>
      <p>We do not share your personal information with third parties except as described in this Privacy Policy or with your consent.</p>

      <h2>5. Security</h2>
      <p>We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.</p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at [whatever you want]@bensa.us.</p>
    </div>
  );
}

export default PrivacyPolicy;