import React from 'react';
import { lineWobble } from 'ldrs'

lineWobble.register()

const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
const LINKEDIN_REDIRECT_URI = process.env.REACT_APP_LINKEDIN_REDIRECT_URI

const LinkedInOAuth = ({ isLoading, onClick }) => {
  const linkedinUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(LINKEDIN_REDIRECT_URI)}&scope=openid%20profile`;

  return (
    <a href={linkedinUrl} className="linkedin-button" onClick={(e) => {
      onClick();
    }}>
      {isLoading ? 
      <l-line-wobble 
      stroke="10" 
      color="white" /> : 'Sign in with LinkedIn'}
    </a>
  );
};

export default LinkedInOAuth;