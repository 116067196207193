import CryptoJS from 'crypto-js';

export function decryptJSON(encryptedData, encryptionKey) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // Checs to see  that the skills in work and projects are the same as the skills in skills
  // console.log("WORK");
  for (const job in decryptedData.work) {
    for (const skill in decryptedData.work[job].skills) {
      if (!decryptedData.skills[decryptedData.work[job].skills[skill]]) {
        throw new Error(`Skill ${decryptedData.work[job].skills[skill]} in work is not in skills`);
      }
    }
  }
  // console.log("PROJECTS");
  for (const project in decryptedData.projects) {
    for (const skill in decryptedData.projects[project].skills) {
      if (!decryptedData.skills[decryptedData.projects[project].skills[skill]]) {
        throw new Error(`Skill ${decryptedData.projects[project].skills[skill]} in projects is not in skills`);
      }
    }
  }

  return decryptedData;
}
