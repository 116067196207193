import React from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { CiLink } from "react-icons/ci";
import FunText from './FunText';
import { iconMap } from './iconMap';

function MainContent({ content, isDarkMode, toggleDarkMode, isFunMode, toggleFunMode }) {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <nav>
        <ul>
          <li><a href="#" className="nav-button" onClick={() => scrollToSection('personal-info')}>info</a></li>
          <li><a href="#" className="nav-button" onClick={() => scrollToSection('education')}>edu</a></li>
          <li><a href="#" className="nav-button" onClick={() => scrollToSection('work-experience')}>exp</a></li>
          <li><a href="#" className="nav-button" onClick={() => scrollToSection('skills')}>skills</a></li>
          <li><a href="#" className="nav-button" onClick={() => scrollToSection('projects')}>projs</a></li>
        </ul>
      </nav>
      <div id="content" ref={content} className={isFunMode ? 'fun-mode-active' : ''}>
        <section id="personal-info">
          <div>
            <h1><FunText text={content.name} isFunMode={isFunMode} /></h1>
            <div className="buttons-menu">
              <DarkModeSwitch
                className="buttons"
                checked={isDarkMode}
                onChange={toggleDarkMode}
              />
              <button className="buttons" onClick={toggleFunMode}>
                <h5> {isFunMode ? 'I\'m Boring' : 'Let\'s Have Fun!'} </h5>
              </button>
            </div>
            <p><FunText text={content.bio} isFunMode={isFunMode} /></p>
            <h4 className="code"><FunText text={'links'} isFunMode={isFunMode} /></h4>
            <ul>
              {content.socials.map((social, index) => (
                <li key={index}>
                  <a href={social.url} alt={social.name}> 
                    {social.custom_image_src.includes(':')
                      ? iconMap[social.custom_image_src.replaceAll(":","")]
                      : <img className="social-icon" src={social.custom_image_src} />}
                    <FunText text={' '} isFunMode={isFunMode} /> </a>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section id="education">
          <div>
            <h2><FunText text="Education" isFunMode={isFunMode} /></h2>
            <ul>
              {content.education.map((edu, index) => (
                <li key={index}>
                  <div className="details">
                    <h3><FunText text={edu.degree} isFunMode={isFunMode} /></h3>
                    <p>
                      <FunText 
                        text={edu.gpa ? `@${edu.college} (GPA: ${edu.gpa})` : '@'+edu.college} 
                        isFunMode={isFunMode} 
                      />
                    </p>
                    {edu.favorite_classes && (
                      <>
                        <h4><FunText text="Favorite Classes" isFunMode={isFunMode} /></h4>
                        <ul className="favorite-classes">
                          {edu.favorite_classes.map((class_, index) => (
                            <li key={index}>
                              <a href={class_.link}>
                                <FunText text={class_.name} isFunMode={isFunMode} />
                              </a>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section id="work-experience">
          <div>
            <h2><FunText text="Work Experience" isFunMode={isFunMode} /></h2>
            <ul>
              {content.work.map((job, index) => (
                <li key={index}>
                  <div className="details">
                    <h3><FunText text={`${job.role} at ${job.company_name}`} isFunMode={isFunMode} /></h3>
                    <h4><a href={job.href}><FunText text="check it out " /><CiLink /> </a> </h4>
                    <p><FunText text={`${job.start} - ${job.end}`} isFunMode={isFunMode} /></p>
                    <p><FunText text={job.description} isFunMode={isFunMode} /></p>
                    <h4><FunText text="Skills Used" isFunMode={isFunMode} /></h4>
                    <ul className="skills skills-used">
                      {job.skills.map(skill_name => (
                        <li key={skill_name} data-skill-name={skill_name}>
                          <a href="#skills"> 
                            {content.skills[skill_name].custom_image_src.includes(':')
                              ? iconMap[content.skills[skill_name]?.custom_image_src.replaceAll(":","")]
                              : <img src={content.skills[skill_name]?.custom_image_src} alt={skill_name} />}
                            </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section id="skills" className="skills">
          <div>
            <h2><FunText text="Skills" isFunMode={isFunMode} /></h2>
            <ul>
              {Object.entries(content.skills).map(([skillName, skillData]) => (
                <li key={skillName} data-skill-name={skillName}>
                  <a href="#skills"> 
                  {skillData.custom_image_src.includes(':')
                    ? iconMap[skillData.custom_image_src.replaceAll(":","")]
                    : <img src={skillData.custom_image_src} alt={skillName} />}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section id="projects">
          <div>
            <h2><FunText text="Projects" isFunMode={isFunMode} /></h2>
            <ul>
              {content.projects.map((project, index) => (
                <li key={index}>
                  <div className="details">
                    <div className="project-header">
                      <h3><FunText text={project.name} isFunMode={isFunMode} /> </h3> 
                      <a href={project.link}><CiLink /></a>
                    </div>
                      <p><FunText text={`Date: ${project.date}`} isFunMode={isFunMode} /></p>
                    <p><FunText text={project.description} isFunMode={isFunMode} /></p>
                    <h4><FunText text="Skills Applied" isFunMode={isFunMode} /></h4>
                    <ul className="skills skills-used">
                      {project.skills.map(skill_name => (
                        <li key={skill_name} data-skill-name={skill_name}>
                          <a href="#skills"> 
                          {content.skills[skill_name]?.custom_image_src.includes(':')
                            ? iconMap[content.skills[skill_name]?.custom_image_src.replaceAll(":","")]
                            : <img src={content.skills[skill_name]?.custom_image_src} alt={skill_name} />}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section id="footer">
          <div>
            <p>Found a mistake? Oops! I'm still a work in progress and my site is too! Like a fine wine, this will get better with time... or at least that's what I tell myself!</p>
          </div>
        </section>
      </div>
    </>
  );
}

export default MainContent;
